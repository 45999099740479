import { BaseHandler } from './modules/base-handler.js';
import { MapHandler } from './modules/map-handler.js';
import mapNearbyList from "../templates/partials/map-list-nearby.hbs";

$.getScript("https://maps.googleapis.com/maps/api/js?key=" + MapHandler.GetApiKey()).done(function() { //script, textStatus

  
  //Get current page base
  //const baseInfo = BaseHandler.Geo.GetBaseInfo();
  let cnt = 0, nearbyData;
  if (typeof loadedData === "object") {
    nearbyData = loadedData;
  }
  const baseguid = $("#cf_basemap").data("base");
  const displayNationalMap = () => {console.warn("Cannot find the base to load. Displaying National Map");
    const nbase = BaseHandler.Geo.GetNationalBase();
    MapHandler.InitBaseMap("cf_basemap",nbase);
    MapHandler.GetNearByList($("#cf_basemap_list"),nearbyData,mapNearbyList);
  };
  
  if (baseguid) {
    const getBaseGuid = () => {
      let defer = $.Deferred();
      BaseHandler.Geo.GetBaseByGuid(baseguid, () => {
        if (window.basestatus === "found" && window.baseInfo) {
          defer.resolve();
        }
        if (window.basestatus === "error") {
          defer.reject();
        }
      });
      return defer.promise();
    };
    $.when(getBaseGuid()).then(()=> {
      MapHandler.InitBaseMap("cf_basemap",window.baseInfo); //Get from global variable
      MapHandler.GetNearByList($("#cf_basemap_list"),nearbyData,mapNearbyList);
    },()=> {
      displayNationalMap();
    });
  } else {
    displayNationalMap();
  }

});
