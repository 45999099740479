const localize = require('./localize.js');
export const BaseHandler = {
  Debug : {
    Mode : function() {
      if (!window.debug) window.debug = "";
      let params = document.location.search;
      if (params) {
        window.debug = params.indexOf("json") < 0 ? "" : "json";
        window.debug = params.indexOf("reload") < 0 ? "" : "reload";
      }
      return window.debug;
    },
    SetMode : function(mode) {
      //mode ? "" : this.DisableLogger();
      window.debug = mode;
    },
    //Function: Disable console.log()
    SetLogger : function(logger) {
      if (logger) {
        let params = document.location.search;
        if (params.indexOf("debug") < 0) {
          //console.log = function() {}
        }
      }
    },
    StorageTest : () => {
      const sessionKey = "sessionTest", localKey = "localTest";
      let session = false;
      try { 
        window.sessionStorage.setItem(sessionKey,"test"); //console.log("session state is set to: " + window.sessionStorage.getItem("basepage"));
        session = window.sessionStorage.getItem(sessionKey);
        window.sessionStorage.removeItem(sessionKey);
      } catch(e) {
        session = false;
      }
      return (session) ? true : false;
    }
  },
  Key : {
    Expires : "cf_expires",
    GeoInfo : "cf_geoinfo",
    Base : "base",
    //BaseInfo : "cf_baseinfo",
    Consent : "cf_consent",
    NoBase : "cf_nobase",
    GeoSampleJson : "/json/sample-geo.json",
    BaseSampleJson : "/json/sample-base.json",
    GeoData : ["ip","country_name","region_code","region_name","city","zip","latitude","longitude"],
    ApiA : ["32b5","13f6"], //flagmented code (A) for ipstack
    ApiB : ["75c7","b244","62a6","de0c","fb60","aecf"], //flagmented code (B) for ipstack
    ApiBSeq : [0,1,2,4,3,5] //sequence: apiA[1] kb[apiBSeq] apiA[0]
  },
  Setting : {
    DistanceLimit : 1000,
    DistanceMax : 40000
  },
  Message : {
    NoBase : localize.get("base.message.nobase"),
  },
  Error : {
    CookieNotFound : localize.get("base.error.nocookie"),
    NoSampleData : localize.get("base.error.nosampledata"),
    NoAPIData : localize.get("base.error.noapidata"),
    NoAPIConnection : localize.get("base.error.noapiconn"),
    NoBaseAPIConnection : localize.get("base.error.nobaseapiconn"),
  },
  Data : {
    GetExpiryDate : function(expiry) {
      if (expiry === "max") {
        return 60*60*24*365;
      }
      let digit = 4, format = "hour";
      //If max, return a year value in seconds
      if (typeof expiry === "string") {
        digit = isNaN(expiry.split(":")[0]) ? 2 : parseInt(expiry.split(":")[0]);
        format = expiry.split(":")[1];
      }
      let exdate;
      switch (format){
        case "year":
          exdate = new Date().setFullYear(new Date().getFullYear() + digit);
          break;
        case "month":
          exdate = new Date().setMonth(new Date().getMonth() + digit);
          break;
        case "day":
          exdate = new Date().setDate(new Date().getDate() + digit);
          break;
        case "hour":
        default:
          exdate = new Date().setHours(new Date().getHours() + digit);
          break;
      }
      
      // let expiryDate = new Intl.DateTimeFormat("default", { 
      //   weekday: "short", 
      //   day: "2-digit", 
      //   month: "short", 
      //   year: "numeric", 
      //   hour: "2-digit", 
      //   minute: "2-digit", 
      //   second:"2-digit", 
      //   hour12: false,
      //   timeZone: "UTC", 
      //   timeZoneName: "shortOffset" }).format(exdate);

      let expiryDate = new Date(exdate).toUTCString();
        //console.log("Expiry is set to: " + expiryDate);
      return expiryDate;
    },
    //Check if the saved value is expired
    CheckExpires : function(data) {
      let expired = true, exdate;
      if (this.CheckStorage() === "local") {
        exdate = expires[2];
      }
      if (this.CheckStorage() === "cookie") {
        
        const expires = data.match(new RegExp('(^| )expires=([^;]+)'));
        if(expires) {
          exdate = expires[2];  
        } else {//If value is null, don't expire
          exdate = new Date();
        }
      }
      if (!isNaN(Date.parse(exdate)) && !isNaN(new Date(exdate))) {
        expired = new Date(exdate) < new Date();
      }
      return expired;
    },
    CheckStorage : function() {
      if (!window.storage) {
        let test = "check";
        try {
          window.localStorage.setItem(test, test);
          window.localStorage.removeItem(test);
          window.storage = "cookie";//not using local storage
        } catch(e) {
          window.storage = "cookie";
        }
      }
      return window.storage;
    },
    AskConsent : function() {
      //console.log("Checking consent...");
      //Set timer to 4 seconds before run
      const $cookieAlert = $("#" + BaseHandler.Key.Consent);
      if (!BaseHandler.Data.Get(BaseHandler.Key.Consent) && BaseHandler.Data.Get(BaseHandler.Key.Consent) !== "seen") {
        //console.log("Consent not found. Opening cookie alert. ");
        $cookieAlert
          .addClass("revealed")
          .attr("aria-hidden", "false")
          .find("button")
            .on("click", function () {
              BaseHandler.Data.Set(BaseHandler.Key.Consent,"seen","1:year");
              $cookieAlert.attr("aria-hidden", "true").removeClass("revealed");
            });
        }
    },
    RemoveAll : function() {
      //console.log("Removing all cookies...");
      const cookies = [BaseHandler.Key.GeoInfo, BaseHandler.Key.Base];
      cookies.forEach((ck) => {
        BaseHandler.Data.Remove(ck);
      });
      return true;
    },
    Remove : function(key) {
      if (this.CheckStorage() === "local") {
        window.localStorage.removeItem(key);
      }
      if (this.CheckStorage() === "cookie") {
        document.cookie = key +"=;expires=Thu, 01 Jan 1970 00:00:01 GMT";
      }
    },
    //Set: Cookie baking process
    Set : function(key,val,expire) {
      let chocolate = key, oatmeal = encodeURIComponent(val), egg = expire, water = "; ", pastry = "string", milk = "expires", vanilla = "max-age";
      
      //store in a fridge
      if (this.CheckStorage() === "local") {
        window.localStorage.setItem(chocolate, oatmeal);
        window.localStorage.setItem(BaseHandler.Key.Expires, this.GetExpiryDate(egg));
      }
      
      //or leave it in a room temperature
      if (this.CheckStorage() === "cookie") {
        let cookies = "";          
        const baking = (sugar, butter, flour) => {
          flour = typeof flour === pastry ? flour : water;
          let baked = sugar ? sugar + "=" + butter : sugar;
          baked = baked + flour;
          return baked;
        };
        //If cookies exists, add to the pile. 
        if (document.cookie) {
          cookies = document.cookie.substring(0, milk + "=");
          //If cookie val has current key, remove from string.
          if (cookies.indexOf(chocolate + "=") >= 0) {
            let crumble = cookies.substring(cookies.indexOf(chocolate + "="));
            crumble = crumble.substring(0,crumble.indexOf(water));
            cookies = cookies.replace(crumble,"");
            //console.log("crmble: "+crumble);
          }
          cookies = cookies + baking(chocolate, oatmeal);
          
        } else { //Or, bake fresh one
          cookies = baking(chocolate, oatmeal);
        }
        egg && egg.indexOf("max") >= 0 ? cookies = cookies + baking(vanilla, this.GetExpiryDate(egg)) : cookies = cookies + baking(milk, this.GetExpiryDate(egg));
        cookies = cookies + "; path=/; SameSite=None; secure; ";
        // if (egg === "1:year") {
        //   cookies = cookies + baking(vanilla, this.GetExpiryDate(egg));
        // }
        //console.log(cookies)
        document.cookie = cookies;
        window.cookies = document.cookie; 
        window.cookiesNotSaved = true;
      } 
    },
    Get : function(key) {
      let val = "";
      if (this.CheckStorage() === "local") {
        val = window.localStorage.getItem(key);
        if(this.CheckExpires(window.localStorage.getItem(BaseHandler.Key.Expires))) {
          val = "";
        }
      }
      if (this.CheckStorage() === "cookie") {
        //Check if page is not refreshed, get value from global.
        let cookieVal = window.cookiesNotSaved ? window.cookies : document.cookie;
        cookieVal = decodeURIComponent(cookieVal);
        //If cookie value exists, enter here
        //const match = cookieVal.match(new RegExp('(^| )' + key + '=([^;]+)'));console.log("Matched? "  + match);
        //if (match) val = match[2];
        if (!this.CheckExpires(cookieVal)) {
          const match = cookieVal.match(new RegExp('(^| )' + key + '=([^;]+)'));
          if (match) val = match[2];
        }
      }
      //if (val.indexOf("{") >= 0) {
        //val = JSON.parse(val)
      //}
      return val;
    },
  },
  Geo : {
    //Function: SetGeoInfo()
    //Set GEO info based from IP stack. This function calls only once and set into Cookie.
    //
    ReadGeoApiData : function (_callback) {
      //Check cookie first
      if(!BaseHandler.Debug.StorageTest()) {
        console.warn("Cookie is not supported in your browser. Please enable first.");
        return false;
      }
      
      //Cookie not saving issue
      if (window.location.protocol !== "https:") {
        console.warn("Your browser is not secured and cookies are disabled. ");
        return false;
      }

      //If no session existed, set session to active
      if (!window.sessionStorage.getItem("basepage")) {
        //console.log("No session exists.");
        //BaseHandler.Data.RemoveAll();
        window.sessionStorage.setItem("basepage","active");
        //console.log("session state is set to: " + window.sessionStorage.getItem("basepage"));
      }
      //Page refreshed and cookie value is there, it means cookie existed so reload function can rely on refresh cookie (for not infinite page refresh)
      if (window.sessionStorage.getItem("basepage") === "pagereload" && BaseHandler.Data.Get("base")) {
        window.sessionStorage.setItem("sessioncount",-1);
      }
      
      
      // if (BaseHandler.Geo.CheckBase()) {

      //   if (geoInfo) {console.log("Both Geo & Base found in cookie. Exiting the process.")
      //     return false;
      //   }
      //   console.log("geo info not found.")
      // } else {
        //Check session variable for session. if session variable doesn't exist, fresh cookie.
        //This might not needed anymore since they want year cookie.

        //I don't understand this statement
        // if (window.sessionStorage.getItem("basepage") === "reloaded") {
        //   BaseHandler.Data.RemoveAll();
        //   window.sessionStorage.setItem("basepage","active"); console.log("session state is set to: " + window.sessionStorage.getItem("basepage"));
        //   return false;
        // }
      //}
      //if no session exists, fresh start
      let geoInfo = this.GetGeoInfo();
      if (geoInfo) {
        _callback(geoInfo);
      }
      //Do nothing if geo and base info is already set.
      if (!geoInfo) { // check if Geo info exists in cookie. Yes: callback, No: access to API to get geo info
        
        window.cookiesNotSaved = false;
        if (BaseHandler.Debug.Mode() === "json") { //reducing access to API for testing
          $.getJSON(BaseHandler.Key.GeoSampleJson).done(function(data) {
            //console.log("%cGetting sample data. If you see this on live, please turn off debug mode.", "color:red;font-weight:700;");
            BaseHandler.Data.Set(BaseHandler.Key.GeoInfo,JSON.stringify(data),"1:year");
            _callback(data);
          }).fail(function() {
            console.warn(BaseHandler.Error.NoSampleData);
          });
        } else {
          //console.log("Calling Geo API...");
          const apiIpUrl = "https://api.ipstack.com/check";
          const cptkeys = BaseHandler.Key.GeoData;
          let apiAcckey = getApikey(BaseHandler.Key.ApiBSeq);
          
          //I don't understand why this info sitting here...
          // baseList.forEach((base, key) => {
          //   currDist = BaseHandler.Calculate.Distance(base.latitude, base.longitude, geoInfo.latitude, geoInfo.longitude);
          //   if (currDist < theDist || theDist === -1) {
          //     theDist = currDist;
          //     theBase = base;
          //   }
          // });
          geoInfo = {}; //reset for retrieve
          const url = apiIpUrl + "?access_key=" + apiAcckey
          $.getJSON(url).done(function(data) {
            if (data.ip){
              $.each(data,function(k,v) {
                cptkeys.forEach((cptkey) => {
                  k === cptkey ? geoInfo[k] = v : "";
                });
              });
              BaseHandler.Data.Set(BaseHandler.Key.GeoInfo,JSON.stringify(geoInfo),"1:year");
              window.geoInfo = geoInfo;
              _callback(geoInfo);
            } else {
              console.warn(BaseHandler.Error.NoAPIData);
              //BaseHandler.Geo.ShowBaseAlert();
            }
          }).fail(function() {
            console.warn(BaseHandler.Error.NoAPIConnection);
            //BaseHandler.Geo.ShowBaseAlert();
          });
        }
      }
      function getApikey(seq) {
        const ka = BaseHandler.Key.ApiA, kb = BaseHandler.Key.ApiB;
        let acckey = "";
        kb.forEach((k,i) => {
          //console.log(kb[seq[i]-1] +":"+i+":"+seq[i])
          //console.log(i +"==="+ kb.length +":"+ kb[seq[i]])
          acckey = i === kb.length - 1 ? ka[1] + acckey + kb[seq[i]] + ka[0] : acckey + kb[seq[i]];
        });
        return acckey;
      }
    },
    //function: GetGeoInfo()
    //Get Geo Info from cookie or global variable
    //return type : object
    GetGeoInfo : function () {
      let geoInfo = BaseHandler.Data.Get(BaseHandler.Key.GeoInfo);
      if (geoInfo && typeof geoInfo === "string") {
        //console.log("Geo info found...");console.log(geoInfo);
        geoInfo = JSON.parse(geoInfo);
        window.geoInfo = geoInfo;
      }
      if (!geoInfo) {
        if (window.geoInfo) {
          geoInfo = window.geoInfo; //Check if geoInfo is in global variable
        } else {
          console.warn("Geo info not found. Please refresh the page to retrieve.");
        }
      }
      return geoInfo;
    },
    //Function: SetBaseInfo() - obsolete function
    //Set Base Info in JSON format
    // SetBaseInfo : function(baseInfo) {
    //   if (!baseInfo.baseguid) {
    //     console.warn("Your cookie is corrupted. Removing your cookies.");
    //     BaseHandler.Data.RemoveAll();
    //     return false;
    //   }
    //   BaseHandler.Data.Set(BaseHandler.Key.BaseInfo,JSON.stringify(baseInfo));
    // },
    //Function: GetBase()
    //Set Base GUID only
    // SetBase : function(baseInfo) {
      
    //   if (!baseInfo || !baseInfo.baseguid) {
    //     console.warn(BaseHandler.Error.CookieNotFound);
    //     BaseHandler.Geo.ShowBaseAlert();
    //     return false;
    //   }
    //   BaseHandler.Data.Set(BaseHandler.Key.Base,baseInfo.baseguid);
    // },
    CheckBase : function() {
      const base = BaseHandler.Data.Get(BaseHandler.Key.Base);
      if (base) {
        return true;
      }
      if (window.baseInfo) {//when info is saved in global variables
        if (typeof window.baseInfo.baseguid !== "undefined") {
          return true;
        }
      }
      return false;
    },
    //Function: GetBaseInfo
    //return type: object
    GetBaseInfo : function(geoInfo,_callback) {
      //console.log(window.baseInfo);
      //console.log("Current session: " + window.sessionStorage.getItem("basepage"));
      if (window.baseInfo) {
        _callback(window.baseInfo);
      }
      window.baseInfo = "";
      window.basestatus = "";
      //Check to see if base info is in cache
      const baseguid = BaseHandler.Data.Get(BaseHandler.Key.Base);
      if (baseguid && baseguid !== "0000-000000-000000-0000") {
        BaseHandler.Geo.GetBaseByGuid(baseguid, (baseInfo) => {
          _callback(baseInfo);
          return;
        });
      } else {
        //Determine geo info
        if (!geoInfo) {
          geoInfo = this.GetGeoInfo();
        }
        //Still cannot retrieve Geo info, assign national base
        if(geoInfo) {
          
          //If no base info in cache, read base list and get closest distance location
          window.basestatus = "ready";
          BaseHandler.Geo.ReadBaseFeed ((baseList) => {
            if (baseList) {
              let currDist, theDist = -1, theBase;
              //console.log("Reading base from API and now calculating the distance...");
              window.basestatus = "reading";
              baseList.forEach((base) => {
                currDist = BaseHandler.Calculate.Distance(base.latitude, base.longitude, geoInfo.latitude, geoInfo.longitude);
                if (currDist < theDist || theDist === -1) {
                  theDist = currDist;
                  theBase = base;
                }
              });
              
              //If base distance is longer than the limit, no return base info
              if (theDist > BaseHandler.Setting.DistanceLimit) {
                //console.log("It is more than limit of " + BaseHandler.Setting.DistanceLimit + "km");
                theDist = theBase = "";
              }
              if (theBase) {
                //console.log("Found one... The distance is: " + theDist);
                //console.log(theBase);
                window.baseInfo = theBase;
                window.basestatus = "found";
                window.sessionStorage.setItem("basepage","reload");
                
                let scnt = parseInt(window.sessionStorage.getItem("sessioncount"));
                scnt = isNaN(scnt) ? 0 : scnt + 1;
                window.sessionStorage.setItem("sessioncount",scnt);
                //console.log("session state is set to: " + window.sessionStorage.getItem("sessioncount"));
                _callback(theBase);
              }
            }
          });
        } else {
          //this.SetBaseInfo(this.GetNationalBase());
          _callback(this.GetNationalBase());
          return;
        }
      }
    },
    GetBaseByGuid : function(guid,_callback) {
      let cnt = 0;
      window.basestatus = "ready";
      
      BaseHandler.Geo.ReadBaseFeed ((baseList) => {
        window.basestatus = "reading";
        baseList.forEach((base) => {
          if (base.baseguid === guid) {
            window.basestatus = "found";
            window.baseInfo = base;
            _callback(base);
          }     
        });
        if (window.basestatus !== "found") {
          window.basestatus = "error";
          _callback();
        }
      });
    },
    //Resetting/Initializing process should be handled here.
    // InitialAccess : function () {
    //   //If base info exists, not initial access.
    //   const baseInfo = BaseHandler.Data.Get(BaseHandler.Key.BaseInfo);
    //   if (baseInfo) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    ReadBaseFeed : function (_callback) {//This area should be done later
      const apiBaseListPath = BaseHandler.Debug.Mode() === "json" || location.host.indexOf("localhost") >= 0 ? BaseHandler.Key.BaseSampleJson : "https://" + location.host + "/api/basefeed/";
      $.getJSON(apiBaseListPath).done(function(data) {
        _callback(data);
      }).fail(function() {console.log(apiBaseListPath);
        console.warn(BaseHandler.Error.NoBaseAPIConnection);
        //BaseHandler.Geo.ShowBaseAlert();
        return false;
      });
    },
    GetNationalBase : function () {
      return { basename : "1 Canadian Air Division Headquarters", latitude : 49.3987583, longitude : -113.98799310, baseguid : "0000-000000-000000-0000", type: 4};
    },
    //This function is a intermediate function to redirect to the base. It's same thing to grab base info first and use GoTo
    SetMyBase : function (geoInfo) {
      $(document).ready(function(){
        if(BaseHandler.Data.Get("base") == ""){
          $("#selectCommunity .national-btn").addClass("active");
          BaseHandler.Data.Set(BaseHandler.Key.Base,$("#selectCommunity .national-btn input").val(),"max");
          //$(".select-community").click();
          setTimeout(function () {
            $(".select-community").click();
          }, 10000);
        }
      });
      //let counter = 0;
      //If geo info in cookie, process to get base info
       /*if (geoInfo && Object.keys(geoInfo).length > 0) {

        BaseHandler.Geo.GetBaseInfo(geoInfo,(baseInfo) => {
            
          let cnt = 0;
          let timer = setInterval(()=>{console.log("status: " + window.basestatus);
            if (window.basestatus === "found") {
              clearInterval(timer);
              if (baseInfo.baseguid) {
                // console.log("ajkjsafkldjlsdafjl");
                // console.log("refresh status: " + (window.sessionStorage.getItem("basepage") === "reload" && parseInt(window.sessionStorage.getItem("sessioncount")) < 1));
                // console.log(window.sessionStorage.getItem("basepage") === "reload" + " " + window.sessionStorage.getItem("basepage") + ":" + parseInt(window.sessionStorage.getItem("sessioncount")) );
                // console.log("*****");
                if (window.sessionStorage.getItem("basepage") === "reload" && parseInt(window.sessionStorage.getItem("sessioncount")) < 1) {console.log("Base cookie is now set. Page reloading...");
                  window.sessionStorage.setItem("basepage","pagereload");console.log("session state is set to: " + window.sessionStorage.getItem("basepage"));  
                  console.log("Session Count is: " + window.sessionStorage.getItem("sessioncount"));
                  //Set base cookie
                  BaseHandler.Data.Set(BaseHandler.Key.Base,baseInfo.baseguid,"max");
                  console.log(baseInfo);  
                  if(BaseHandler.Debug.Mode() == "reload") {
                    confirm("Reload the page?") ? window.location.reload() : "";
                  } else {
                    window.location.hash ? window.location.reload() : window.location.href = window.location.href;
                  }
                }
              } else {
                console.warn(BaseHandler.Error.CookieNotFound);
                BaseHandler.Geo.ShowBaseAlert();
              }
            }
            cnt = cnt < 3 ? cnt ++ : (clearInterval(timer),defer.reject());
          },10);
         
        });
        
        //this baseinfo isn't returning. instead grab it from window.baseInfo

      }*/
      //else {
        //When base info isn't available, set base to national
        //BaseHandler.Data.Set(BaseHandler.Key.GeoInfo,"");
        //baseInfo = this.GetNationalBase();
      //}
    },
    //If base doesn't exists, show alert
    ShowBaseAlert : function () {
      if (!BaseHandler.Geo.CheckBase()) {
        const id = BaseHandler.Key.NoBase, message = BaseHandler.Message.NoBase;
        let link = message.substring(message.indexOf("{{"),message.indexOf("}}") + 2); //assuming only one element here
        $(".alert-container").prepend("<div id=" + id + " data-closable>" + message.replace(link,"<a href='#selectCommunity' class='modal-link'>" + link.substring(2,link.length - 2) + "</a>") + "</div>");
        $("#"+id).addClass("callout alert-nobase").append("<button class='close-button' aria-label='close' type='button' data-close><span aria-hidden='true'>&times;</span></button>");
        setTimeout(() => {
          $("#"+id).addClass("show").on("click",".close-button", () => {
            $("#"+id).removeClass("show");
            //BaseHandler.Data.Set(id,1);
          });
        },1500);
        $(".alert-container .modal-link").on("click",()=>{
          $(".select-community").trigger("click");
        });
        //console.log("No base cookie found. Showing national base alert. ");
        
        window.sessionStorage.setItem("basepage","pagereload");
        //console.log("session state is set to: " + window.sessionStorage.getItem("basepage"));//stop refreshing
      } else { 
        //BaseHandler.Data.Set(id,1);
        //console.log("base cookie exists. No alert.");
      }
    }, 
    //Redirects to base
    // GoTo : function (baseInfo) {console.log("Go to ...");
    //   //It has a bit of lag to get info here. set timer to wait a bit.
    //   let counter = 0;
    //   const timer = setInterval(function() {
        
    //     if (!baseInfo) {
    //       if (window.baseInfo) baseInfo = window.baseInfo;
    //     }
    //     console.log(baseInfo);
    //     if (baseInfo) {
    //       clearTimeout(timer);
    //       if (!baseInfo.basepath) {
    //         console.warn(BaseHandler.Error.CookieNotFound);
    //         BaseHandler.Data.RemoveAll();
    //         return false;
    //       }
          
    //       //If my base info is there, do nothing.
    //       if (!baseInfo.mybase) {
    //         let goto = baseInfo.basepath.replace("~","")
    //         BaseHandler.Geo.SetBase(baseInfo);
    //         location.href = goto;
    //       } else {
    //         console.log("It was redirected before so not redirecting again")
    //       }
    //     }
    //     //Try 5 times
    //     if (counter > 5) {
    //       clearTimeout(timer);
    //     }
    //     counter ++;
    //   },100);
    // },
    // ShowMyLocation : function ($location,geoInfo) {
    //   BaseHandler.Geo.GetBaseInfo(geoInfo, function(baseInfo) {
    //     console.log("My location is: " + baseInfo.name);
    //     $location.text(baseInfo.name);
    //   });
    // }
  },
  //Calculate distance in kilometer
  Calculate : {
    Distance : function (lat1, lon1, lat2, lon2) {
      
      const numCheck = (num) => {
        if(!num) num = 0;
        num = isNaN(num) ? 0 : parseFloat(num);
        return num;
      };
      lat1 = numCheck(lat1);
      lon1 = numCheck(lon1);
      lat2 = numCheck(lat2);
      lon2 = numCheck(lon2);
      
      if (lat1 === 0 || lat2 === 0) {
        return BaseHandler.Setting.DistanceMax;
      }
      //Radius of the earth in:  1.609344 miles,  6371 km  | var R = (6371 / 1.609344);
      var R = 6371; // Radius of earth in kilo meter 
      var dLat = this.ToRad(lat2-lat1);
      var dLon = this.ToRad(lon2-lon1); 
      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.cos(this.ToRad(lat1)) * Math.cos(this.ToRad(lat2)) * 
              Math.sin(dLon/2) * Math.sin(dLon/2); 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c;
      return d;
    },
    ToRad : function (val) {
      return val * Math.PI / 180;
    }
  },
  GetSession : (key) => {

  }
};
