const localize = require('./localize.js');
const infoboxTemplate = require("../../templates/partials/map-infobox.hbs");
export const MapHandler = {
  Settings : {
    InfoBox : {
      Option : {
        ariaLabel: "arial",
        disableAutoPan: false,
        zIndex: 0,
        boxStyle: {
          background: "#2C3238",
          padding: "0",
          width: "280px"
        },
        closeBoxURL : "",
        isHidden: false,
        pane: "floatPane",
        enableEventPropagation: false
      }
    },
    Pin : {
      Default : "red",
      Highlight : "black"
    },
    GKey : ["AIzaSyADSq9peeY6gKGCYli4kMXh989kDfp8khY"] //<--- CFMWS
    //GKey : ["AIzaSyDUFHRS-6muAMjc3aew9V1ca6Pzs83_vog"] //<--- Daisuke Test
  },
  InitBaseMap : function(mapId,baseInfo) { //var maincord = {lat: 56.530366, lng: -100.346771}; //{lat: -25.363, lng: 131.044};

    if (!baseInfo || typeof baseInfo.latitude !== "string") {
      console.warn("Cannot find your base coordinate to set. Please make sure you have setup.");
      return false;
    }
    ///init global objects
    window.markerList = [];
    window.infoboxList = [];
    window.markerIndex = 0;
    window.mapBounds = new google.maps.LatLngBounds();
    
    //Check browser size, if it's less than 836px, change zoom
    let zoom = 10;
    if($(window).width() <= 836) {
      zoom = 3;
    }
    window.cfmap = new google.maps.Map(document.getElementById(mapId), {
      zoom: zoom,
      maxZoom: 14,
      draggable: true,
      center: MapHandler.GetLatLng(baseInfo),
      mapTypeId: google.maps.MapTypeId.ROADMAP
      //styles: this.GetStyles()
    });
    MapHandler.SetMarker(baseInfo, MapHandler.GetMarkerStyle("base"));
  },
  GetMap : function() {
    return window.cfmap;
  },
  SetMarker : (location, _options) => {
    location = MapHandler.ReformatLocationJson(location);
    const latlng = MapHandler.GetLatLng(location);
    let markerOptions = {
      id:window.markerIndex,
      position: latlng,
      map: window.cfmap,
      title: location.name,
      draggable: true,
      zIndex: window.markerIndex
    };
    //Create marker
    const marker = new google.maps.Marker(Object.assign(markerOptions, _options));
    window.markerList.push(marker);

    //Set bounds if pin exists
    if (window.markerIndex > 0) {
      //If there are only one available and the difference is within 100m, don't bound
      let bound = true;
      if (window.markerIndex === 1) {
        let difflat = Number.parseFloat(latlng.lat() - window.markerList[0].position.lat()).toFixed(3);
        let difflng = Number.parseFloat(latlng.lng() - window.markerList[0].position.lng()).toFixed(3);
        if ((difflat <= 0.001 && difflat >= -0.001) &&
          (difflng <= 0.001 && difflng >= -0.001)) {
          bound = false;
        }
        console.log(difflng +":"+ difflat)
      }
      console.log("bound: " + bound + window.markerIndex)
      if (bound) {
        window.mapBounds.extend(latlng);
        window.cfmap.fitBounds(window.mapBounds);
      }
    }    
    window.markerIndex ++;
    
    location = Object.assign(location, {"direction":"<a href='https://www.google.com/maps/dir/?api=1&" + 
    "&destination=" + location.latitude + "," + location.longitude + "' class='arrow-right' target='_blank'>" + localize.get("basemap.linktext") + "</a>"});
    const infoboxContent = infoboxTemplate(location);
    //  "<div class='info-box'>" +
    //   wrapHtmlTag(wrapHtmlTag(location.name,"strong"),"p") +
    //   wrapHtmlTag(location.address,"p") + 
    //   wrapHtmlTag(location.phone,"p") + 
    //   wrapHtmlTag("<a href='" + location.path + "'>" +  + "</a>","p") + 
    //   "</div>";
       
      // function wrapHtmlTag(text,tag) {
      // if (text) {
      //   return tag === "br" ?
      //     text + "<" + tag + " />" :
      //     "<" + tag + ">" + text + "</" + tag + ">";
      // }
      // return "";
    //}  
    //create infobox
    const infobox = new google.maps.InfoWindow({
      content: infoboxContent,  
      location: MapHandler.Settings.InfoBox.Option,
    });
    window.infoboxList.push(infobox);
    
    google.maps.event.addListener(marker,"click", () => {
      MapHandler.OpenInfoBox(marker,infobox);
    });
    google.maps.event.addListener(infobox,'closeclick',function(){
      MapHandler.ResetMarker(marker);

    });
    return marker;
  },
  CloseCurrentMarker: () => {
    console.log("Closing marker...");
    if(window.currMarker) {
      let m = window.currMarker.icon.name;
      m = m ? m : "";
      const ms = MapHandler.GetMarkerStyle(m);
      marker.setIcon(ms.icon);
    }
  },
  ResetMarker: (marker) => {
    if (marker.zIndex) {
      let m = marker.icon.name;
      m = m ? m : "";
      const ms = MapHandler.GetMarkerStyle(m);
      marker.setIcon(window.currIcon);
      //marker.setLabel(null);

    }
  },
  HighlightMarker : (marker) => {
    const currIcon = marker.icon;
    const ms = MapHandler.GetMarkerStyle(MapHandler.Settings.Pin.Default);
    marker.setIcon(ms.icon);  
    window.currIcon = currIcon;
  },
  OpenInfoBox: ((marker,infobox) => {
    //close if current marker exists
    if (window.currMarker) {
      MapHandler.ResetMarker(window.currMarker);
    }
    //close if current infobox opened
    if (window.currInfoBox) {
      window.currInfoBox.close();
    }
    MapHandler.HighlightNearby(marker);

    //set current marker before change
    window.currMarker = marker; 

    //Label//marker.setLabel(String.fromCharCode(marker.zIndex + 64));
    if (marker.zIndex > 0) {
      MapHandler.HighlightMarker(marker);
    }
    
    
    //marker.setAnimation(google.maps.Animation.DROP);
    setTimeout(function() {
      infobox.open({
        anchor: marker,
        map: window.cfmap,
        shouldFocus: true,
      });
      
      window.currInfoBox = infobox;
    },400);
  }),
  GetLatLng : (latLng) => {
    let lat = 56.530366;
    let lng = -100.346771;
    if(latLng) {
      if (latLng.latitude) lat = latLng.latitude;
      if (latLng.longitude) lng = latLng.longitude;
    }
    return new google.maps.LatLng(lat, lng);
  },
  HighlightNearby : (marker) => {
    const clsActive = "is-active";
    let nid = "#nearby_" + marker.id;
    if (typeof window.currMarker === "object") {
      $( "#nearby_" + window.currMarker.id).removeClass(clsActive);
    }
    $(nid).addClass(clsActive);
  },
  GetNearByList : ($container,nearbyList,nearbyTemplate) => {
    console.log("Getting nearby list...");
    console.log(nearbyList);
    if (nearbyList) {
      //Append near by list
      nearbyList.forEach((item, key) => {
        let newobj = MapHandler.ReformatLocationJson(item);
        newobj = Object.assign(newobj, {"id":"nearby_" + window.markerIndex});
        $container.append(nearbyTemplate(newobj));
        const marker = MapHandler.SetMarker(newobj, MapHandler.GetMarkerStyle(newobj.pin));
        document.getElementById(newobj.id).addEventListener("click",()=> {
          new google.maps.event.trigger(marker,"click");
        });
      });
    } else {
      $container.hide();
      console.warn("No near by list found. If you have setup, your map selection might not be made.");
    }
  },
  GetMarkerStyle : (pin) => {
    pin = typeof pin === "string" ? pin : "default";
    const icons = {
      red: {
        icon: {
          url: "/CFMWS/media/images/icons/icon_map_pin1.svg",
          size: new google.maps.Size(20, 28),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(10, 28),
        }
      },
      black: {
        icon: {
          url: "/CFMWS/media/images/icons/icon_map_pin2.svg",
          size: new google.maps.Size(20, 28),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(10, 28),
        }
      },
      blue: {
        icon: {
          url: "/CFMWS/media/images/icons/icon_map_pin3.svg",
          size: new google.maps.Size(20, 28),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(10, 28),
        }
      },
      yellow: {
        icon: {
          url: "/CFMWS/media/images/icons/icon_map_pin4.svg",
          size: new google.maps.Size(20, 28),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(10, 28),
        }
      },
      base: {
        icon: {
          url: "/CFMWS/media/images/icons/icon_map_pin.svg",
          size: new google.maps.Size(0, 0),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(0, 0),
        }
      },
      // default: {
      //   icon: {
      //     path: google.maps.SymbolPath.CIRCLE,
      //     scale: 2.5,
      //     fillColor: "#DA291C",
      //     fillOpacity:1,
      //     strokeColor: "#DA291C"
      //   }
      // },
    };
    let pinDefault = icons[MapHandler.Settings.Pin.Default],
      pinHighlight = icons[MapHandler.Settings.Pin.Highlight]

    //return icon with name
    if(icons[pin]) {
      return Object.assign(icons[pin],{"name":pin});
    } else {
      return Object.assign(pinDefault,{"name":"default"});
    }
  },
  //Reformatting  base/nearby JSON to match with Map
  ReformatLocationJson : function(obj) {
    const formatAddress = (arr,dlm) => {
      dlm = typeof dlm === "string" ? dlm : ", ";
      let address = "";
      arr.forEach((item,k) => {
        address += address && item ? dlm : "";
        address += item ? item : "";
      });
      return address;
    };
    const concat = (a,b) => {
      let c = "";
      if (a) {
        c = b ? a + b : "";
      }
      return c;
    };
    const maskcphone = (p) => {
      if (p) {
        const cacc = "+1";//, mask = "+1 (###) ###-####";
        var cc = p.indexOf(cacc) >= 0 ? cacc : "";
        var phone = p.replace(cacc,"").replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
        return !p[3] ? p : cc + " (" + phone[1] + ") " + phone[2] + "-" + phone[3];
      }
      return false;
    };

    let eqobj = (obj.name) ? {
      name: obj.name,
      latitude: obj.latitude,
      longitude: obj.longitude,
      address: concat(obj.address,"<br>") + concat(obj.address2,"<br>") + formatAddress([obj.city,obj.province]) + concat(" ",obj.postalcode),
      phone: obj.phone,
      phoneF: maskcphone(obj.phone),
      direction: "",
      pin: obj.pin ? obj.pin : "default"
    } : {
      name: obj.basename,
      latitude: obj.latitude,
      longitude: obj.longitude,
      address: "",
      phone: "",
      direction: "",
      pin: "default"
    }
    return eqobj;
  },
  //Get Google API key. Each are separated by dash & underscore just to make harder to grab string all in once.
  GetApiKey : function() {
    const gkey = this.Settings.GKey;
    return gkey[0];
  },
  EmbedMap : ($tgt,id,mapparams) => {

    const mapUrl = "https://www.google.com/maps/embed/v1/place";

    let imap = document.createElement("iframe");
    imap.id = id;
    
    if (mapparams) {
      imap.setAttribute("src", mapUrl + mapparams + "&key=" + MapHandler.GetApiKey());
      $tgt.html(imap);
    } else {
      $tgt.hide();
    }
    $tgt.removeClass("is-hidden");
  }
}