module.exports = {
  data : {
    en : {
      "domain": "localhost",
      "basemap.linktext" : "Get directions",
      "base.message.nobase" : "We were unable to automatically determine your location. Please {{select your base}} to view regional information.",
      "base.error.nocookie" : "No base cookie found. ",
      "base.error.nosampledata" : "Cannot get the sample data. Please check the sample file.",
      "base.error.noapidata" : "Could not retrieve data. Please check your API.",
      "base.error.noapiconn" : "Cannot connect to API. Please contact your web administrator.",
      "base.error.nobaseapiconn" : "Cannot connect to base API. Please contact your web administrator.",
      "discount.filter.location.usecurrentlocation" : "(Your location)",
      "discount.filter.location.usegeolocation" : "(Your general location)",
      "discount.filter.location.usebaselocation" : "(current base)",
      "discount.filter.location.usenolocation" : "(unknown location)",
      "discount.tote.distance.morethan1000" : "> 1000 KM",
      "discount.tote.distance.lessthan1" : "< 1 KM",
      "discount.tote.unlockspecial" : "Unlock Special",
      "discount.error.locationdenied" : "Location access denied. Please {{enable location access}} for more precise results.",
      "discount.error.loading" : "Error loading discounts. Please try changing your location.",
      "discount.autocomplete.selectgenerallocation" : "Or, <span>select your general location</span>",
      "calculator.error.nodata" : "There are no data for the selected plan.",
      "calculator.error.loaddata" : "Error loading data.",
      "calculator.dropdown.default" : "Please select",
      "login.error.validation" : "Please review your entries and address the errors indicated in the form.",
      discount : {
        tote : {
          unlockspecial : "Unlock Special"
        }
      }
      // province : {
      //   AB : "Alberta",
      //   BC : "British Columbia",
      //   MB : "Manitoba",
      //   NB : "New Brunswick",
      //   NL : "Newfoundland and Labrador",
      //   NT : "Northwest Territories",
      //   NS : "Nova Scotia",
      //   NU : "Nunavut",
      //   ON : "Ontario",
      //   PE : "Prince Edward Island",
      //   QC : "Quebec",
      //   SK : "Saskatchewan",
      //   YT : "Yukon Territory"
      // },
    },
    fr : {
      "domain":"192.168.0.17",
      "basemap.linktext" : "Get direction (Fr)",
      "base.message.nobase" : "Nous n'avons pas été en mesure de déterminer automatiquement votre emplacement. Veuillez {{sélectionner votre emplacement}} pour afficher les informations régionales.",
      "base.error.nocookie" : "Aucun cookie de base trouvé.",
      "base.error.nosampledata" : "Impossible d'obtenir les exemples de données. Veuillez vérifier le fichier d'exemple.",
      "base.error.noapidata" : "Impossible de récupérer les données. Veuillez vérifier votre API",
      "base.error.noapiconn" : "Cannot connect to API. Please contact your web administrator.",
      "base.error.nobaseapiconn" : "Impossible de se connecter à l'API. Veuillez contacter votre administrateur Web.",
      "discount.filter.location.usecurrentlocation" : "(Votre emplacement)",
      "discount.filter.location.usegeolocation" : "(Votre emplacement général)",
      "discount.filter.location.usebaselocation" : "(base actuelle)",
      "discount.filter.location.usenolocation" : "(lieu inconnu)",
      "discount.tote.distance.morethan1000" : "> 1000 KM",
      "discount.tote.distance.lessthan1" : "< 1 KM",
      "discount.tote.unlockspecial" : "Déverrouillez le spécial",
      "discount.error.locationdenied" : "Accès à la localisation refusé. Veuillez {{autoriser l'accès à la localisation}} pour des résultats plus précis.",
      "discount.error.loading" : "Erreur lors du chargement des remises. Veuillez essayer de modifier votre emplacement.",
      "discount.autocomplete.selectgenerallocation" : "Ou, <span>sélectionnez votre emplacement général</span>",
      "calculator.error.nodata" : "Il n'y a pas de données pour le plan sélectionné.",
      "calculator.error.loaddata" : "Erreur lors du chargement des données.",
      "calculator.dropdown.default" : "Veuillez sélectionner",
      "login.error.validation" : "Veuillez vérifier vos entrées et corriger les erreurs indiquées dans le formulaire.",
      // province : {
      //   AB : "Alberta",
      //   BC : "British Columbia",
      //   MB : "Manitoba",
      //   NB : "New Brunswick",
      //   NL : "Newfoundland and Labrador",
      //   NT : "Northwest Territories",
      //   NS : "Nova Scotia",
      //   NU : "Nunavut",
      //   ON : "Ontario",
      //   PE : "Prince Edward Island",
      //   QC : "Quebec",
      //   SK : "Saskatchewan",
      //   YT : "Yukon Territory"
      //},
    }
  },
  //****** Localilze */
  //*     code  [string]
  //*   return  [string]
  get : function (code) {//console.log("localize get fired:" + code)
    return this.lang() === "fr-CA" ? this.data.fr[code] : this.data.en[code];
  },
  text : function(code) {
    return this.get(code);
  },
  //****** Lang */
  lang : function () {
    return $("body").hasClass("fr-CA") ||
      (this.data.en.domain !== this.data.fr.domain && location.hostname.match(this.data.fr.domain)) ? "fr-CA" : "en-CA";
  }
};
